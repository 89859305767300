'use client';

import { LoggedInUserProps } from '@interfaces/auth';
import { USER_ROLE } from '@utils/auth/roles';
import { v2Links } from '@utils/navigation/links';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuTrigger,
  HomeLine,
  Logo,
  Logout01,
  Star01,
  Typography,
  User01,
  UserCheck01,
  typographyVariants,
} from '@v2/ui';
import { cn, themeConfig } from '@v2/utils';
import { signOut } from 'next-auth/react';
import Link from 'next/link';
import { usePathname, useRouter } from 'next/navigation';
import { CurrencyDropdown } from '../CurrencyPicker/CurrencyDropdown';
import { HeaderSearch } from '../Search/HeaderSearch';
import { MobileMenu } from './components/MobileMenu/MobileMenu';
import { SportsDropdown } from './components/SportsDropdown/SportsDropdown';

type HeaderBgVariant = 'white' | 'black';
export type HeaderProps = {
  user: LoggedInUserProps | null;
  variant?: HeaderBgVariant;
  withSearchbar?: boolean;
  children?: React.ReactNode;
};
export const Header = (props: HeaderProps) => {
  const { user, variant = 'white', withSearchbar = false } = props;

  const isBlackMode = variant === 'black';

  const buttonStyle = isBlackMode
    ? 'bg-white border border-gray-300 text-gray-800 '
    : 'bg-slate-500/25 border-transparent text-white';

  return (
    <header className="relative z-10 mx-auto mb-8 mt-0 flex h-[72px] w-full max-w-7xl items-center justify-between px-4 md:mb-20 md:h-24 xl:px-0">
      <div className="flex">
        <Link href={v2Links.home}>
          <Logo className="mr-8" variant={variant} />
        </Link>
        <div className="flex flex-row gap-x-5">
          {withSearchbar && (
            <div className="hidden lg:block">
              <HeaderSearch variant={variant} />
            </div>
          )}
          <nav
            className={`hidden font-semibold md:block ${isBlackMode ? 'text-gray-800' : 'text-white'}`}
          >
            <ul className="flex h-full items-center gap-3 text-center">
              <li className="inline">
                <SportsDropdown buttonStyle={buttonStyle} variant={variant} />
              </li>
              <li className="inline w-16">
                <Link href="https://blog.elitesportstours.com/">Blog</Link>
              </li>
              {/* <li className="inline w-16">
                <Link href="/concerts">
                  Concerts
                </Link>
              </li> */}
            </ul>
          </nav>
        </div>
      </div>
      <div
        className={`flex gap-4 ${isBlackMode ? 'text-gray-800' : 'text-white'}`}
      >
        <div className="hidden md:block">
          <CurrencyDropdown variant={variant} />
        </div>

        {!user && (
          <Link
            href={v2Links.login}
            className="hidden md:block"
            prefetch={false}
          >
            <button
              className={cn(
                `${typographyVariants({ size: 'tmd' })} h-12 w-24 rounded-lg ${buttonStyle} `
              )}
            >
              Sign in
            </button>
          </Link>
        )}

        <MobileMenu user={user} />

        {user && <UserNav user={user} variant={variant} />}
      </div>
    </header>
  );
};

export function UserNav({
  user,
  variant,
}: {
  user: LoggedInUserProps;
  variant: HeaderBgVariant;
}) {
  const router = useRouter();
  const pathname = usePathname();
  const userLastNameChar =
    user?.lastName && user?.lastName?.length > 0 ? user.lastName[0] : '';

  const isLandingpage = pathname === '/';
  const isPartnerslandingPage = pathname === '/sports-travel-agent-network';
  const userRole = user.role;

  const canAccessPartner = [
    USER_ROLE.agency,
    USER_ROLE.agencyMember,
    // USER_ROLE.individualAgent,
  ].includes(userRole);

  return (
    <div className="hidden md:block">
      <DropdownMenu>
        <DropdownMenuTrigger>
          <div className="flex flex-row items-center gap-x-3">
            <Typography
              variant="h4"
              size="tmd"
              className={cn(
                'font-semibold',
                variant === 'black' && 'text-gray-800',
                variant === 'white' && 'text-white'
              )}
            >
              {user.firstName}&nbsp;{user.lastName}
            </Typography>
            <Avatar
              className={cn(
                variant === 'white' && 'bg-[#98A2B329] backdrop-blur-sm',
                variant === 'black' && 'border border-gray-200',
                'h-12 w-12'
              )}
            >
              <AvatarImage src={user.imageUrl} className="object-cover" />

              <AvatarFallback
                className={typographyVariants({
                  size: 'tmd',
                  className: `font-semibold uppercase ${isLandingpage ? 'text-gray-400' : variant === 'black' ? 'text-gray-800' : 'text-white'}`,
                })}
              >
                {`${user.firstName[0]}${userLastNameChar}`}
              </AvatarFallback>
            </Avatar>
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="min-w-[194px] bg-white" align="end">
          <DropdownMenuSub>
            {isPartnerslandingPage && (
              <DropdownMenuItem
                className={cn('flex cursor-pointer items-center gap-x-2.5')}
                onSelect={() => {
                  router.push(v2Links.home);
                }}
              >
                <HomeLine size="20" className="text-gray-500" />
                Homepage
              </DropdownMenuItem>
            )}
            {!canAccessPartner && (
              <>
                <DropdownMenuItem
                  className={cn('flex cursor-pointer items-center gap-x-2.5')}
                  onSelect={() => {
                    router.push(v2Links.favorites);
                  }}
                >
                  <Star01
                    size="20"
                    pathProps={{ stroke: themeConfig.theme.colors.gray[500] }}
                  />
                  Favorites
                </DropdownMenuItem>
                <DropdownMenuItem
                  className={cn('flex cursor-pointer items-center gap-x-2.5')}
                  onSelect={() => {
                    router.push(v2Links.profile);
                  }}
                >
                  <User01
                    size="20"
                    pathProps={{ stroke: themeConfig.theme.colors.gray[500] }}
                  />
                  Account
                </DropdownMenuItem>
              </>
            )}
            {canAccessPartner && (
              <DropdownMenuItem
                className={cn('flex cursor-pointer items-center gap-x-2.5')}
                onSelect={() => {
                  router.push(v2Links.partnersOverview);
                }}
              >
                <UserCheck01
                  size="20"
                  pathProps={{ stroke: themeConfig.theme.colors.gray[500] }}
                />
                Partner
              </DropdownMenuItem>
            )}
          </DropdownMenuSub>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            className={cn('flex cursor-pointer items-center gap-x-2.5')}
            onSelect={async () => {
              if (canAccessPartner) {
                await signOut({ callbackUrl: v2Links.partnersLanding });
              } else {
                await signOut();
              }
            }}
          >
            <Logout01
              size="20"
              pathProps={{ stroke: themeConfig.theme.colors.gray[500] }}
            />
            Log out
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}
